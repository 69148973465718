import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const ServiceImage = ({ img }) => {
    const data = useStaticQuery(graphql`
    query {
      kernbohrung: file(relativePath: { eq: "services/kernbohrung.jpg" }) {
        childImageSharp {
          fluid(quality: 60, maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hochbau: file(relativePath: { eq: "services/hochbau.jpg" }) {
        childImageSharp {
          fluid(quality: 60, maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tiefbau: file(relativePath: { eq: "services/tiefbau.jpg" }) {
        childImageSharp {
          fluid(quality: 60, maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pflasterbau: file(relativePath: { eq: "services/pflasterbau.jpg" }) {
        childImageSharp {
          fluid(quality: 60, maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      umbau: file(relativePath: { eq: "services/umbau.jpg" }) {
        childImageSharp {
          fluid(quality: 60, maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      kran: file(relativePath: { eq: "services/kranarbeiten.jpg" }) {
        childImageSharp {
          fluid(quality: 60, maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      reparatur: file(relativePath: { eq: "services/reparatur.jpg" }) {
        childImageSharp {
          fluid(quality: 60, maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    if (img === "kernbohrung") {
        return <Img fluid={data.kernbohrung.childImageSharp.fluid} />
    }
    if (img === "hochbau") {
        return <Img fluid={data.hochbau.childImageSharp.fluid} />
    }
    if (img === "tiefbau") {
        return <Img fluid={data.tiefbau.childImageSharp.fluid} />
    }
    if (img === "pflasterbau") {
        return <Img fluid={data.pflasterbau.childImageSharp.fluid} />
    }
    if (img === "umbau") {
        return <Img fluid={data.umbau.childImageSharp.fluid} />
    }
    if (img === "kranarbeiten") {
        return <Img fluid={data.kran.childImageSharp.fluid} />
    }
    if (img === "reparatur") {
        return <Img fluid={data.reparatur.childImageSharp.fluid} />
    }
    else {
        return React.Fragment;
    }
}

export default ServiceImage