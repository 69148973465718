import React from "react";
import { StaticQuery, graphql } from "gatsby";

export class Jobs extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <section id="jobs" className="container">
                    <div className="row my-5 jobs">
                        <div className="col-md-12">
                            <h1 className="my-5">Stellenangebote</h1>
                        </div>
                        <div className="col-10 offset-1">
                            <div className="row">
                                <div className="col-md-6">
                                    <p>
                                        {this.props.jobs.nodes[0].description}
                                    </p>
                                    {this.props.jobs.nodes[0].jobs.map((item, key) =>
                                        <div key={'jobs-' + key} className="job">
                                            <h4>
                                                {item.job.title}
                                            </h4>
                                            <p>
                                                {item.job.description}
                                            </p>
                                        </div>
                                    )}
                                </div>
                                <div className="col-md-6">
                                    <img className="img-fluid" src={this.props.jobImage.childImageSharp.fluid.src} />
                                </div>
                            </div>
                        </div>
                    </div>
            </section>
        )
    }
}

export default () => (
    <StaticQuery
        query={graphql`
      query allJobsQuery {
        allJobsYaml{
            nodes {
              description
              jobs{
                job{
                  title
                  description
                }
              }
            }
        }
        jobImage: file(relativePath: {eq: "jobs/teamarbeit.jpg"}) {
            childImageSharp {
              fluid(quality: 60, maxWidth: 1000) {
                src
              }
            }
          }
    }
      `}
        render={(data) => (
            <Jobs jobs={data.allJobsYaml} jobImage={data.jobImage} />
        )}
    />
)