import React from "react";
import { StaticQuery, graphql } from "gatsby";

import "./contact.scss";

export class Contact extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <section id="contact" className="container-fluid py-5 bg-alternative">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="my-5">Kontakt</h1>
                            <h4 className="my-5">
                                {this.props.contact.nodes[0].contactPage.description}
                            </h4>
                        </div>
                        <div className="col-md-10 offset-md-1">
                            <div className="row my-5">
                                <div className="col-md-6 text-center">
                                    <div className="contact-person w-100">
                                        <img className="rounded-circle" src={this.props.contactImage.childImageSharp.fluid.src} />
                                        <h5 className="mt-3">
                                            {this.props.contact.nodes[0].contactPage.contact.name}
                                        </h5>
                                        <p>
                                            {this.props.contact.nodes[0].contactPage.contact.title}<br/>
                                    <span>
                                        <small><strong>fon</strong></small> {this.props.contact.nodes[0].contactPage.contact.phone}
                                    </span><br />
                                        </p>
                                    </div>
                                    <span>
                                        <small><strong>fax</strong></small> {this.props.contact.nodes[0].contactPage.contact.fax}
                                    </span>
                                </div>
                                <div className="col-md-6">
                                    <form className="contact-form" name="contact" method="POST" data-netlify="true">
                                        <input type="hidden" name="form-name" value="contact" />
                                        <div className="form-group">
                                            <label>Name</label>
                                            <input className="form-control" type="text" name="name" required />
                                        </div>
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input className="form-control" type="email" name="email" required />
                                        </div>
                                        <div className="form-group">
                                            <label>Nachricht</label>
                                            <textarea className="form-control" name="message" required></textarea>
                                        </div>
                                        <div className="form-group float-right">
                                            <button className="btn btn-primary" type="submit">Absenden</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default () => (
    <StaticQuery
        query={graphql`
      query allContactQuery {
        allContactYaml{
            nodes{
              contactPage {
                description
                contact{
                  title
                  name
                  phone
                  fax
                }
              }
            }
          }
            contactImage: file(relativePath: {eq: "contact/portrait_1.jpg"}) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 600) {
                src
              }
            }
          }
    }
      `}
        render={(data) => (
            <Contact contact={data.allContactYaml} contactImage={data.contactImage} />
        )}
    />
)