import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./references.scss";

export class References extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 2,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        };

        return (
            <section id="references" className="container-fluid">
                <div className="my-5 row bg-alternative">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className="my-5">Referenzen</h1>
                            </div>
                            <div className="col-md-10 offset-md-1 mb-5">
                                <Slider {...settings}>
                                    {this.props.references.edges.map((item, key) =>
                                        <div className="slick-image" key={key}>
                                            <img src={item.node.childImageSharp.fluid.src} alt="Referenz"></img>
                                        </div>
                                    )}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default () => (
    <StaticQuery
        query={graphql`
        query allReferencesQuery {
            images: allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {eq: "references"}}) {
              edges {
                node {
                    childImageSharp {
                        fluid(quality: 60, maxWidth: 800) {
                          src
                        }
                    }
                }
              }
            }
          }`}
        render={(data) => (
            <References references={data.images} />
        )}
    />
)