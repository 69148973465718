import React from "react";
import { StaticQuery, graphql } from "gatsby";

import ServiceImage from '../serviceimage/serviceImage';

import "./services.scss";

export class Services extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            description: '',
            image: '',
            activeServiceId: ''
        }
    }

    componentDidMount() {
        if (this.props.services.nodes) {
            const service = this.props.services.nodes[0].service;
            this.selectService(service, 0);
        }
    }

    selectService(service, key) {
        if (service) {
            this.setState({
                ...this.state,
                title: service.title,
                description: service.description,
                image: service.image,
                activeServiceId: 'service-' + key
            })
        }
    }

    render() {
        return (
            <section id="services" className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="mb-5">Leistungen</h1>
                    </div>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-3 d-none d-sm-block">
                                <div className="list-group services">
                                    {this.props.services.nodes.map((item, key) =>
                                        <button
                                            type="button"
                                            key={'svcb-' + key}
                                            onClick={() => this.selectService(item.service, key)}
                                            className={this.state.activeServiceId === 'service-' + key ? 'list-group-item list-group-item-action active' : 'list-group-item list-group-item-action'}>{item.service.title}
                                        </button>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-4 d-none d-sm-block">
                                <h2>{this.state.title}</h2>
                                <p>{this.state.description}</p>
                            </div>
                            <div className="col-md-5 d-none d-sm-block">
                                <ServiceImage img={this.state.image} />
                            </div>
                        </div>
                        <div className="row d-block d-sm-none">
                            {this.props.services.nodes.map((item, key) =>
                                <React.Fragment key={'svc-' + key}>
                                    <div key={'svcd-' + key} className="col-md-4">
                                        <h2>{item.service.title}</h2>
                                        <p>{item.service.description}</p>
                                    </div>
                                    <div key={'svci-' + key} className="col-md-4 mb-4">
                                        <ServiceImage img={item.service.image} />
                                    </div>
                                </React.Fragment>
                            )}
                        </div>

                    </div>
                </div>
            </section>
        )
    }
}

export default () => (
    <StaticQuery
        query={graphql`
      query allServicesQuery {
        allServicesYaml {
            nodes {
                service {
                    title
                    description
                    image
                }
            }
        }
    }
      `}
        render={(data) => (
            <Services services={data.allServicesYaml} />
        )}
    />
)