import React from "react";
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators
} from "reactstrap";
import Img from "gatsby-image";

import "./slider.scss";

export default class Slider extends React.Component {
    constructor(props) {
        super(props);
        this.state = { activeIndex: 0 };
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
        this.items = props.items;
    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex =
            this.state.activeIndex === this.items.length - 1
                ? 0
                : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex =
            this.state.activeIndex === 0
                ? this.items.length - 1
                : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }
    render() {

        const { activeIndex } = this.state;

        const slides = this.items.map(item => {
            return (
                <CarouselItem
                    className="w-100"
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={item.image.childImageSharp.fluid.src}
                >
                    <Img
                        alt={item.heading + " " + item.heading2}
                        fluid={item.image.childImageSharp.fluid}
                    />
                    <div className="carousel-caption">
                        <div className="slide-title">
                            <span className="slide-title-code">{item.heading1}</span>
                            <span className="slide-title-topic">{item.heading2}</span>
                        </div>
                        <p className="slide-subtitle">{item.caption}</p>
                    </div>
                </CarouselItem>
            );
        });

        return (
            <Carousel
                activeIndex={activeIndex}
                next={this.next}
                previous={this.previous}
            >
                <CarouselIndicators
                    items={this.items}
                    activeIndex={activeIndex}
                    onClickHandler={this.goToIndex}
                />
                {slides}
                <CarouselControl
                    direction="prev"
                    directionText="Previous"
                    onClickHandler={this.previous}
                />
                <CarouselControl
                    direction="next"
                    directionText="Next"
                    onClickHandler={this.next}
                />
            </Carousel>
        );
    }
}
