import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Slider from "../components/slider/slider";
import Services from "../components/services/services";
import References from "../components/references/references";
import Jobs from "../components/jobs/jobs";
import Contact from "../components/contact/contact";

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.setSlider();
    this.checkIe = this.checkIe.bind(this);
    this.state = {
      ie11: false
    };
  }
  componentDidMount() {
    this.checkIe();
  }
  checkIe() {
    var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
    this.setState({
      ie11: isIE11
    });
  }
  setSlider() {
    this.items = [
      {
        image: this.props.data.slide5,
        heading2: "Wir suchen DICH",
        altText: "Maschinist, Maurer oder als Azubi!",
        caption: "Maschinist, Maurer oder als Azubi!"
      },
      {
        image: this.props.data.slide4,
        heading2: "Tiefbau",
        altText: "Ausheben, betonieren und verfüllen...",
        caption: "Ausheben, betonieren und verfüllen..."
      },
      {
        image: this.props.data.slide2,
        heading2: "Präzision",
        altText: "Ihr Vorhaben ist unser Auftrag!",
        caption: "Ihr Vorhaben ist unser Auftrag!"
      },
      {
        image: this.props.data.slide3,
        heading2: "Fertigbetonteile",
        altText: "Wir verbauen auch Fertigbetonteile.",
        caption: "Wir verbauen auch Fertigbetonteile."
      },
      {
        image: this.props.data.slide6,
        heading2: "Baukran",
        altText: "Für die Bauprojekte bringen wir den Baukran mit.",
        caption: "Für die Bauprojekte bringen wir den Baukran mit."
      },
      {
        image: this.props.data.slide1,
        heading2: "Alexander Ruoß",
        altText: "Ihr Bauunternehmer in Wittendorf.",
        caption: "Ihr Bauunternehmer in Wittendorf."
      }
    ];
  }
  render() {
    return (
      <Layout>
        <SEO title="Home" />
        <div className="container-fluid">
          <div id="home" className="row">
            <div className="col-12 p-0">
              <section id="slider">
                {!this.state.ie11 && <Slider items={this.items} />}
                {this.state.ie11 && (
                  <Img fluid={this.props.data.slide1.childImageSharp.fluid} />
                )}
              </section>
            </div>
          </div>
        </div>
        <Services />
        <References />
        <Jobs />
        <Contact />
      </Layout>
    )
  }
}
export const query = graphql`
 query IndexQuery {
   slide1: file(relativePath: { eq: "slider/impression-4.jpg" }) {
     ...sliderImage
   }
   slide2: file(relativePath: { eq: "slider/impression-8.jpg" }) {
    ...sliderImage
   }
   slide3: file(relativePath: { eq: "slider/impression-13.jpg" }) {
   ...sliderImage
   }
   slide4: file(relativePath: { eq: "slider/impression-10.jpg" }) {
   ...sliderImage
   }
   slide5: file(relativePath: { eq: "slider/impression-9.jpg" }) {
   ...sliderImage
   }
   slide6: file(relativePath: { eq: "slider/impression-5.jpg" }) {
   ...sliderImage
   }
 }
`;
